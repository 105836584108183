<template>
  <Header @scroll-to="updateElementName" />
  <Body :element-name="elementName" />
  <Footer />
</template>

<script lang="ts" setup>
import { ref } from "vue";
import Header from "./components/Header.vue";
import Body from "./components/Body.vue";
import Footer from "./components/Footer.vue";

const elementName = ref("");

const updateElementName = (name: string) => {
  elementName.value = name;
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
