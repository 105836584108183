import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref } from "vue";
import Header from "./components/Header.vue";
import Body from "./components/Body.vue";
import Footer from "./components/Footer.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const elementName = ref("");

const updateElementName = (name: string) => {
  elementName.value = name;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(Header, { onScrollTo: updateElementName }),
    _createVNode(Body, { "element-name": elementName.value }, null, 8, ["element-name"]),
    _createVNode(Footer)
  ], 64))
}
}

})