<script lang="ts" setup>
import { defineEmits } from "vue";
import ConnectWallet from "./ConnectWallet.vue";

const emits = defineEmits(["scroll-to"]);

const clickEl = (name: string) => {
  emits("scroll-to", name);
};
</script>

<template>
  <header>
    <nav
      class="bg-primary border-gray-200 sm:px-4 lg:px-6 py-2.5 dark:bg-gray-800 font-ringb text-4xl drop-shadow-md"
    >
      <div
        class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl"
      >
        <a href="https://thebog.vip" class="flex items-center h-20">
          <!-- <img
            src="/images/logo-green-2.png"
            class="mr-3 w-16"
            alt="Bog's Logo"
          /> -->
          <!-- <span
            class="self-center text-4xl font-semibold whitespace-nowrap text-orange"
            >Bog</span
          > -->
        </a>
        <div class="flex items-center lg:order-2">
          <!-- <a
            href="#"
            class="text-gray-800 dark:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
            >Log in</a
          > -->
          <!-- <ConnectWallet /> -->
        </div>
        <div
          class="sm:justify-between sm:items-center sm:flex w-full sm:w-auto sm:order-1"
        >
          <ul
            class="flex font-medium flex-row space-x-8 mt-0 mx-auto w-fit sm:w-auto"
          >
            <li class="hidden sm:block">
              <a
                href="#"
                class="block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
                aria-current="page"
                >Home</a
              >
            </li>
            <!-- <li>
              <a
                href="#"
                class="block py-2 pr-4 pl-3 text-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                @click="clickEl('about')"
                >About</a
              >
            </li> -->
            <li class="hidden sm:block">
              <a
                href="#"
                class="block py-2 pr-4 pl-3 text-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                @click="clickEl('shitenomics')"
                >Bogenomics</a
              >
            </li>
            <li class="hidden sm:block">
              <a
                href="#"
                class="block py-2 pr-4 pl-3 text-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                @click="clickEl('shitmap')"
                >Bog Map</a
              >
            </li>
            <li class="mx-auto contents sm:block">
              <a
                href="/bog-paper.pdf"
                class="block py-2 pr-4 pl-3 text-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                >BOG PAPER</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>
