<template>
  <div>
    <div
      class="py-8 px-0 sm:px-10 md:px-20 md:flex 2xl:px-60 xl:px-30 font-morris"
      ref="aboutRef"
    >
      <div>
        <img src="/images/logo.png" class="w-96" alt="Bog's Logo" />
      </div>
      <div class="text-white flex items-center text-left ml-8">
        <div>
          <span class="text-8xl font-harryp mr-3">$BOG</span>
          <span class="text-4xl">THE LORD OF THE FROGS</span>
          <div class="font-morris">
            <!-- <p class="text-4xl font-harryp">
              Looking for an army to take my stolen Kingdom
            </p> -->
            <p class="text-4xl">NEED ARMY TO TAKE MY STOLEN KINGDOM</p>
            <!-- <p class="text-4xl">Everything has a Point</p>
            <p class="text-4xl">Everything is a Secret</p>
            <p class="text-4xl">It's the property of the bog</p>
            <p class="text-4xl">Read the Bog paper and find the secrets</p> -->
          </div>
        </div>
      </div>
    </div>

    <div
      class="py-8 px-0 sm:px-10 md:px-20 md:flex 2xl:px-60 xl:px-30"
      ref="aboutRef"
    >
      <div class="flex items-center justify-center">
        <a href="https://t.me/bogtoken_official"
          ><img src="/images/telegram.png" class="w-16 mr-2" alt="Bog's Logo"
        /></a>
        <a
          href="https://dexscreener.com/solana/3nwxe5ck7kcnvrnlwqbk1wjcpzmxv2jdx3cwqedzdwg7"
        >
          <img src="/images/dexscreener.png" class="w-16 mr-2" alt="Bog's Logo"
        /></a>
        <a
          href="https://solscan.io/token/8AFk97BkiaSXfgJS4ADQ8vqf7fcJw9nXN4KSTisby5LC"
        >
          <img src="/images/solscan.png" class="w-16 mr-2" alt="Bog's Logo"
        /></a>
        <a href="https://x.com/RyogenBog">
          <img src="/images/twitter.png" class="w-16 mr-2" alt="Bog's Logo"
        /></a>
        <a
          href="https://www.dextools.io/app/en/solana/pair-explorer/3NwxE5cK7kCNVRnLwQbK1wjcpzmxV2jDX3cwqedzdWG7"
          target="_blank"
        >
          <img src="/images/dextools.png" class="w-16 mr-2" alt="Bog's Logo"
        /></a>

        <!-- <a href="https://www.coingecko.com/en/coins/bog1"
          ><img src="/images/coingecko.png" class="w-16" alt="Bog's Logo"
        /></a> -->

        <a href="https://coinmarketcap.com/currencies/bog"
          ><img src="/images/cmc.png" class="w-16" alt="Bog's Logo"
        /></a>
      </div>
    </div>
    <div
      div
      class="flex items-center justify-center py-8 px-0 sm:px-10 md:px-20 md:flex 2xl:px-60 xl:px-30"
    >
      <a
        href="https://jup.ag/swap/SOL-8AFk97BkiaSXfgJS4ADQ8vqf7fcJw9nXN4KSTisby5LC"
      >
        <button
          type="button"
          class="flex w-48 py-2.5 px-5 me-2 mb-2 font-medium text-white focus:outline-none bg-transparent rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        >
          <div class="flex mx-auto">
            <img src="/images/jupiter.png" class="w-6 mr-2" />
            <span>Jupiter</span>
          </div>
        </button>
      </a>
      <!-- <a
        href="https://www.orca.so/?tokenIn=8AFk97BkiaSXfgJS4ADQ8vqf7fcJw9nXN4KSTisby5LC&tokenOut=So11111111111111111111111111111111111111112"
        ><button
          type="button"
          class="flex w-48 py-2.5 px-5 me-2 mb-2 font-medium text-white focus:outline-none bg-transparent rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        >
          <div class="flex mx-auto">
            <img src="/images/orca.png" class="w-6 mr-2" />
            <span>Orca</span>
          </div>
        </button></a
      > -->
      <a
        href="https://raydium.io/swap/?inputMint=8AFk97BkiaSXfgJS4ADQ8vqf7fcJw9nXN4KSTisby5LC&outputMint=sol"
      >
        <button
          type="button"
          class="flex w-48 py-2.5 px-5 me-2 mb-2 font-medium text-white focus:outline-none bg-transparent rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        >
          <div class="flex mx-auto">
            <img src="/images/raydium.png" class="w-6 mr-2" />
            <span>Raydium</span>
          </div>
        </button>
      </a>
    </div>
    <div
      class="py-8 px-0 sm:px-10 md:px-20 md:flex md:flex-row-reverse 2xl:px-60 xl:px-30"
      ref="shitenomicsRef"
    >
      <div>
        <img
          src="/images/bog-2.png"
          class="ml-3 w-96 scale-x-[-1]"
          alt="Bog's Logo"
        />
      </div>
      <div class="text-white">
        <h1 class="text-8xl font-ringb">Bog Supply</h1>
        <div class="font-morris">
          <p class="text-4xl">
            <span>895,534,211,385,321</span>
            <span class="ml-2 text-1xl font-harryp">$BOG</span>
          </p>
          <!-- <p class="text-4xl">Nothing but 1% burn per buy/sell</p>
          <p class="text-4xl">Zero tax on transfer</p> -->
        </div>
      </div>
    </div>

    <div
      class="py-8 px-0 sm:px-10 md:px-20 md:flex 2xl:px-60 xl:px-30"
      ref="shitmapRef"
    >
      <div>
        <img src="/images/bog-3.png" class="mr-3 w-96" alt="Bog's Logo" />
      </div>
      <div class="text-white flex items-center">
        <div>
          <h1 class="text-8xl font-ringb">Bog Map</h1>
          <div class="text-left font-morris">
            <p class="text-4xl">1. BUY</p>
            <p class="text-4xl">2. HODL</p>
            <p class="text-4xl">3. BURN</p>
            <p class="text-4xl">4. REWARD</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, Ref } from "vue";
import { watch } from "vue";
import { defineProps } from "vue";

const aboutRef = ref<HTMLElement | null>(null);
const shitenomicsRef = ref<HTMLElement | null>(null);
const shitmapRef = ref<HTMLElement | null>(null);

const props = defineProps({
  elementName: {
    type: String,
    default: "",
  },
});
// Using scrollIntoView() function to achieve the scrolling
function scrollTo(view: Ref<HTMLElement | null>) {
  view.value?.scrollIntoView({ behavior: "smooth" });
}

const scrollToElement = (name: string) => {
  if (name === "about") {
    scrollTo(aboutRef);
  } else if (name === "shitenomics") {
    scrollTo(shitenomicsRef);
  } else if (name === "shitmap") {
    scrollTo(shitmapRef);
  }
};

watch(
  () => props.elementName,
  (name: string) => {
    scrollToElement(name);
  }
);
</script>
