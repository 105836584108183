import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-primary border-gray-200 sm:px-4 lg:px-6 py-2.5 dark:bg-gray-800 font-ringb text-4xl drop-shadow-md" }
const _hoisted_2 = { class: "flex flex-wrap justify-between items-center mx-auto max-w-screen-xl" }
const _hoisted_3 = { class: "sm:justify-between sm:items-center sm:flex w-full sm:w-auto sm:order-1" }
const _hoisted_4 = { class: "flex font-medium flex-row space-x-8 mt-0 mx-auto w-fit sm:w-auto" }
const _hoisted_5 = { class: "hidden sm:block" }
const _hoisted_6 = { class: "hidden sm:block" }


import ConnectWallet from "./ConnectWallet.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Header',
  emits: ["scroll-to"],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const clickEl = (name: string) => {
  emits("scroll-to", name);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("header", null, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[4] || (_cache[4] = _createElementVNode("a", {
          href: "https://thebog.vip",
          class: "flex items-center h-20"
        }, null, -1)),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "flex items-center lg:order-2" }, null, -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            _cache[2] || (_cache[2] = _createElementVNode("li", { class: "hidden sm:block" }, [
              _createElementVNode("a", {
                href: "#",
                class: "block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white",
                "aria-current": "page"
              }, "Home")
            ], -1)),
            _createElementVNode("li", _hoisted_5, [
              _createElementVNode("a", {
                href: "#",
                class: "block py-2 pr-4 pl-3 text-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (clickEl('shitenomics')))
              }, "Bogenomics")
            ]),
            _createElementVNode("li", _hoisted_6, [
              _createElementVNode("a", {
                href: "#",
                class: "block py-2 pr-4 pl-3 text-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (clickEl('shitmap')))
              }, "Bog Map")
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("li", { class: "mx-auto contents sm:block" }, [
              _createElementVNode("a", {
                href: "/bog-paper.pdf",
                class: "block py-2 pr-4 pl-3 text-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
              }, "BOG PAPER")
            ], -1))
          ])
        ])
      ])
    ])
  ]))
}
}

})