import { createApp } from "vue";
import App from "./App.vue";
import vueWeb3 from "@web3-vue3/core";
import "./index.css";

createApp(App)
  .use(vueWeb3, {
    provider: window["ethereum"],
  })
  .mount("#app");
